import CartQuery from 'Query/Cart.query';
import { updateEmail, updateShippingFields } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import { updateIsLoadingCart } from './Cart.action';
import {
    CartDispatcher as SourceCartDispatcher,
    CURRENT_WEBSITE
} from './Cart.dispatcher.source';

export {
    CURRENT_WEBSITE
};

/** @namespace Scandipwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async updateInitialCartData(dispatch, isForCustomer = false, disableLoader = false) {
        // Need to get current cart from BE, update cart
        try {
            // ! Get quote token first (local or from the backend) just to make sure it exists

            if (!disableLoader) {
                dispatch(updateIsLoadingCart(true));
            }
            // ! Get quote token first (local or from the backend) just to make sure it exists
            const quoteId = await this._getCartId(dispatch);
            const {
                cartData = {},
                cartData: {
                    is_virtual = false,
                    shipping_address: {
                        selected_shipping_method = {},
                        method_code
                    } = {}
                } = {}
            } = await fetchQuery(
                CartQuery.getCartQuery(
                    quoteId
                )
            );

            cartData.gtmCategoryData = await this.formatGtmCategoryData(cartData);

            const {
                address
            } = selected_shipping_method || {};

            const {
                street = null,
                email = ''
            } = address || {};

            if (address && street) {
                if (!is_virtual) {
                    await dispatch(
                        updateShippingFields({
                            ...this.prepareCheckoutAddressFormat(address),
                            method_code
                        })
                    );
                }

                await dispatch(updateEmail(email));
            }

            if (isForCustomer && !getAuthorizationToken()) {
                dispatch(updateIsLoadingCart(false));

                return null;
            }

            await this._updateCartData(cartData, dispatch);

            if (!disableLoader) {
                dispatch(updateIsLoadingCart(false));
            }

            return null;
        } catch (error) {
            dispatch(updateIsLoadingCart(false));

            return this.createGuestEmptyCart(dispatch);
        }
    }

    async formatGtmCategoryData(cartData) {
        try {
            const { items = [] } = cartData;

            if (!items.length) {
                return {};
            }

            const dataToFormat = items.map((item) => ({
                id: item.id,
                ga_categories: item.ga_categories
            })) || [];

            const formattedData = dataToFormat.reduce((acc, { id, ga_categories }) => {
                const categoriesToArray = ga_categories.split('//')?.filter((n) => n);

                return {
                    ...acc,
                    [id]: categoriesToArray
                };
            }, {});

            return formattedData || {};
        } catch (error) {
            return {};
        }
    }

    /**
     * Overridden to return error message in case of an error
     */
    async applyCouponToCart(dispatch, couponCode) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId();

            if (!isCustomerSignedIn && !cartId) {
                return {
                    success: false,
                    errorMessage: ''
                };
            }

            const { applyCouponToCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getApplyCouponMutation(couponCode, cartId)
            );

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));

            return {
                success: true,
                errorMessage: ''
            };
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return {
                success: false,
                errorMessage: getErrorMessage(error)
            };
        }
    }

    async addOrUpdateGiftMessage(options = {}, dispatch, updateCart = true) {
        try {
            await fetchMutation(
                CartQuery.getAddOrUpdateGiftMessage(options)
            );

            if (updateCart) {
                this.updateInitialCartData(dispatch);
            }

            return Promise.resolve({ errors: [] });
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    async changeItemOptions(dispatch, options) {
        const {
            uid, customizableOptions, configurable_options, quantity
        } = options;
        const cartId = getCartId();

        try {
            if (!cartId) {
                return Promise.reject();
            }

            if (customizableOptions) {
                await fetchMutation(
                    CartQuery.getUpdateCartItemsMutation({
                        cart_id: cartId,
                        cart_items: [
                            {
                                cart_item_uid: uid,
                                customizableOptions,
                                quantity
                            }
                        ]
                    })
                );
            }

            await fetchMutation(
                CartQuery.getUpdateCartItemsMutation({
                    cart_id: cartId,
                    cart_items: [
                        {
                            cart_item_uid: uid,
                            configurable_options,
                            quantity
                        }
                    ]
                })
            );

            return this.updateInitialCartData(dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    async addProductToCart(dispatch, options = {}) {
        const { products = [], cartId: userCartId, id = null } = options;

        const cartIdInitial = userCartId || getCartId();
        const cartId = !cartIdInitial ? await this.createGuestEmptyCart(dispatch) : cartIdInitial;

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification('error', __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products, id)
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification('error', getErrorMessage(error)));
                });

                if (products?.length === errors?.length) {
                    return Promise.reject();
                }
            }

            await this.updateInitialCartData(dispatch);
            dispatch(showNotification('success', __('Product was added to cart!')));
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification('error', __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }

        return Promise.resolve();
    }

    async addProductToCartFromDesignRoom(dispatch, options = {}) {
        const { products = [], cartId: userCartId } = options;

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification('error', __('There was an error adding product to cart')));

            return Promise.reject();
        }

        const cartId = userCartId || getCartId();
        if (!cartId) {
            dispatch(showNotification('error', __('There was an error adding product to cart')));

            return Promise.reject();
        }

        try {
            const { addProductsToCartFromDesignRoom: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartFromDesignRoomMutation(cartId, products)
            );

            if (Array.isArray(errors) && errors.length > 0) {
                const someAddedToCart = products.length > errors.length;

                if (someAddedToCart) {
                    dispatch(showNotification('success', __('Some of the items were added to cart')));
                }
                if (products.length - errors.length === 0) {
                    dispatch(showNotification('error',
                        __('Sorry, items could not be added to your cart. '
                            + 'Please review your selections or contact customer support')));
                } else if (products.length - errors.length > 1) {
                    dispatch(showNotification('error',
                        __('Sorry, some items could not be added to your cart. '
                            + 'Please review your selections or contact customer support')));
                } else {
                    dispatch(showNotification('error',
                        __('Sorry, one item could not be added to your cart. '
                            + 'Please review your selections or contact customer support')));
                }

                if (someAddedToCart) {
                    this.updateInitialCartData(dispatch);
                }

                return Promise.resolve({ errors });
            }
            const successMsg = products.length > 1
                ? __('Products were added to cart!') : __('Product was added to cart!');

            dispatch(showNotification('success', successMsg));
            this.updateInitialCartData(dispatch);

            return Promise.resolve({ errors: [] });
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification('error', __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }
}

export default new CartDispatcher();
