/**
 * GA4MeasurementProtocol frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/ga4MeasurementProtocol/Query/GaCookie/Query */
export class GaCookieQuery {
    setGaCookie(gaCid, gaSessionCookie, guestCartId = null, cookie_name, additionalData = {}) {
        return new Field('setGaCookie')
            .addArgument('ga_cookie', 'String!', gaCid)
            .addArgument('ga_session_cookie', 'String!', gaSessionCookie)
            .addArgument('guestCartId', 'String', guestCartId)
            .addArgument('cookie_name', 'String!', cookie_name)
            .addArgument('additionalData', 'String', JSON.stringify(additionalData));
    }

    getGaSessionCookie() {
        return new Field('getGaSessionCookie')
            .addFieldList(this.getGaSessionCookieFields());
    }

    getGaSessionCookieFields() {
        return [
            'ga_session_cookie_observer',
            'ga_session_cookie_cron'
        ];
    }
}

export default new (GaCookieQuery)();
