import PropTypes from 'prop-types';
import { memo, Suspense } from 'react';
import { InView } from 'react-intersection-observer';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { CategoryTreeType } from 'Type/Category.type';
import { GridLayoutTotalColumnType } from 'Type/CategoryPage.type';
import { isCrawler, isSSR } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { AfterPriority, lowPriorityLazy, setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { getCloudflareOptimizationImage } from 'Util/Resize';

// eslint-disable-next-line max-len
import AmastyAutomaticRelatedProductsReducer from '../../../packages/amasty-automatic-related-products/src/store/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProducts.reducer';
import {
    CATEGORY_DROPDOWN_BLACK_LIST,
    CATEGORY_OFFERS_URL_KEY,
    CATEGORY_SLIDER_MAX_ITEMS_MOBILE,
    CategoryPageBlocks,
    GRID_LAYOUT,
    GRID_LAYOUT_MULTIPLE_COLUMNS,
    GRID_LAYOUT_ONE_COLUMN,
    LIST_LAYOUT,
    PRODUCT_LIST_PLACEHOLDER_AMOUNT,
    PRODUCT_LIST_VISIBLE_PRODUCTS_AMOUNT
} from './CategoryPage.config';
import {
    CategoryDetails,
    CategoryFilterOverlay,
    CategoryItemsCount,
    CategoryPage as SourceCategoryPage,
    CategoryProductList,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon
} from './CategoryPage.source.component';

import './CategoryPage.override.style';

export const CategoriesSlider = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */
    'Component/CategoriesSlider'
));
export const CategoryDropdown = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */
    'Component/CategoryDropdown'
));
export const CmsBlock = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms-block" */
    'Component/CmsBlock'
));
export const Menu = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "menu" */
    'Component/Menu'
));

export {
    CategoryDetails,
    CategoryFilterOverlay,
    CategoryItemsCount,
    CategoryProductList,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon
};

/** @namespace Scandipwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    static propTypes = {
        ...SourceCategoryPage.propTypes,
        isThirdLevelCategory: PropTypes.bool.isRequired,
        contentBeforeProductList: PropTypes.bool.isRequired,
        openMenu: PropTypes.func.isRequired,
        closeMenu: PropTypes.func.isRequired,
        isMenuOpen: PropTypes.bool.isRequired,
        gridLayoutTotalColumn: GridLayoutTotalColumnType,
        stickyEntry: PropTypes.instanceOf(IntersectionObserverEntry).isRequired,
        baseUrl: PropTypes.string.isRequired,
        setDefaultLayout: PropTypes.func.isRequired,
        sliderChildren: CategoryTreeType.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    renderMobileLayoutButtonMap = {
        [GRID_LAYOUT]: this.renderMobileGridLayoutButton.bind(this),
        [LIST_LAYOUT]: this.renderMobileListLayoutButton.bind(this)
    };

    renderLayoutButtonMap = {
        [GRID_LAYOUT]: this.renderGridLayoutButton.bind(this),
        [LIST_LAYOUT]: this.renderListLayoutButton.bind(this)
    };

    renderCategoryDropdownItemFallback = this.renderCategoryDropdownItemFallback.bind(this);

    renderProductListItemFallback = this.renderProductListItemFallback.bind(this);

    renderProductListItemPlaceholderFallback = this.renderProductListItemPlaceholderFallback.bind(this);

    renderProductListItemFallbackCard = this.renderProductListItemFallbackCard.bind(this);

    renderSubcategorySliderItemFallback = this.renderSubcategorySliderItemFallback.bind(this);

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const {
            setDefaultLayout
        } = this.props;

        setDefaultLayout();

        // Setting isPriorityLoaded to true while there is no preload
        setLoadedFlag();
    }

    /**
     * Renders content before product list from category configuration
     */
    renderContentBeforeProductList() {
        const {
            category: {
                content_before_product_list: contentBeforeProductList
            }
        } = this.props;

        if (!contentBeforeProductList) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="BeforeProductList"
            >
                <Html content={ contentBeforeProductList } />
            </div>
        );
    }

    /**
     * Overridden to remove category details render method
     */
    renderContent() {
        return (
            <>
                <AfterPriority fallback={ null }>
                    { this.renderContentBeforeProductList() }
                </AfterPriority>
                { this.renderCLPDropdownMenu() }
                <AfterPriority fallback={ this.renderContentFallback() }>
                    { this.renderFilterOverlayWrapper() }
                    { this.renderCmsBlock() }
                    { this.renderMiscellaneous() }
                </AfterPriority>
                { this.renderCategoryProductList() }
            </>
        );
    }

    renderContentFallback() {
        return (
            <>
                <div block="CategoryPageFallback" elem="FilterContainer">
                    <div block="CategoryPageFallback" elem="Sort" />
                    <div block="CategoryPageFallback" elem="Filters" />
                </div>
                <aside block="CategoryPageFallback" elem="Miscellaneous">
                    <div block="CategoryPageFallback" elem="MiscellaneousLayoutWrapper">
                        <div block="CategoryPageFallback" elem="LayoutWrapper">
                            <div block="CategoryPageFallback" elem="LayoutButtons">
                                <div block="CategoryPageFallback" elem="LayoutButton" />
                                <div block="CategoryPageFallback" elem="LayoutButton" />
                            </div>
                            <div block="CategoryPageFallback" elem="CategorySort" />
                        </div>
                    </div>
                </aside>
            </>
        );
    }

    renderCLPDropdownMenu() {
        const {
            location: {
                state: {
                    categoryId
                } = {}
            } = {}
        } = history;

        const {
            category: {
                id,
                name
            } = {},
            openMenu,
            isThirdLevelCategory,
            isMenuUpdateNeeded,
            isMobile
        } = this.props;

        const isBlackListed = CATEGORY_DROPDOWN_BLACK_LIST.includes(name);

        if (isThirdLevelCategory || isMobile) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderCategoryDropdownFallback() }>
                <CategoryDropdown
                  categoryId={ categoryId || id }
                  openMenu={ openMenu }
                  isMenuHidden={ isBlackListed }
                  isMenuUpdateNeeded={ isMenuUpdateNeeded }
                />
            </Suspense>
        );
    }

    renderCategoryDropdownFallback() {
        const {
            actionName: {
                name = '',
                menuChildrenCount = 0
            }
        } = window;

        return (
            <div block="CategoryDropdownFallback">
                <div block="CategoryDropdownFallback" elem="Button">
                    <div block="CategoryDropdownFallback" elem="ButtonContent">
                        <div block="CategoryDropdownFallback" elem="ButtonContentPlaceholder" />
                    </div>
                </div>
                <h2>{ name }</h2>
                <div block="CategoryDropdownFallback" elem="Content">
                    { Array.from({ length: menuChildrenCount }, this.renderCategoryDropdownItemFallback) }
                </div>
            </div>
        );
    }

    renderCategoryDropdownItemFallback() {
        return <div block="CategoryDropdownFallback" elem="Item" />;
    }

    /**
     * New method to render mobile category miscellaneous
     */
    renderMobileMiscellaneous(ref, entry) {
        const threshold = 0;
        const isSticky = entry?.boundingClientRect.top < threshold;

        return (
            <aside
              block="CategoryPage"
              elem="Miscellaneous"
              mods={ { isSticky } }
              ref={ ref }
            >
                <div
                  block="CategoryPage"
                  elem="StickyWrapper"
                >
                    <div
                      block="CategoryPage"
                      elem="MiscellaneousLayoutWrapper"
                      id="CategoryPageMisc"
                      mods={ { isSticky } }
                    >
                        <div
                          block="CategoryPage"
                          elem="LayoutWrapper"
                          mods={ { isPrerendered: isSSR() || isCrawler() } }
                        >
                            { this.renderCategorySort() }
                        </div>
                        <div
                          block="CategoryPage"
                          elem="LayoutWrapper"
                          mods={ { isPrerendered: isSSR() || isCrawler() } }
                        >
                            { this.renderFilterButton() }
                        </div>
                    </div>
                </div>
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                    { this.renderLayoutButtons() }
                    { this.renderItemsCount(true) }
                </div>
            </aside>
        );
    }

    renderLayoutButtons() {
        const { isLayoutLoading, plpTypes } = this.props;

        /*
        * So far there is only two types of
        * the Storefront list modes
         */
        if (plpTypes.length !== 2) {
            return null;
        }

        if (isLayoutLoading) {
            return <Loader isLoading />;
        }

        return (
            <div block="CategoryPage" elem="LayoutButtons">
                { plpTypes.map(this.renderLayoutButton.bind(this)) }
            </div>
        );
    }

    /**
     * Overridden to:
     * - Change position of items number
     * - Add renderMobileMiscellaneous usage
     */
    renderMiscellaneous() {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <InView threshold={ 1 }>
                    { ({ ref, entry }) => this.renderMobileMiscellaneous(ref, entry) }
                </InView>
            );
        }

        return (
            <aside
              block="CategoryPage"
              elem="Miscellaneous"
            >
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderLayoutButtons() }
                        { this.renderItemsCount() }
                        { this.renderCategorySort() }
                    </div>
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderFilterButton() }
                    </div>
                </div>
            </aside>
        );
    }

    renderFilterOverlay(afterRef, beforeRef) {
        const {
            category: {
                is_anchor
            },
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage
        } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="FilterContainer"
              id="FilterContainerId"
            >
                <div
                  block="CategoryPage"
                  elem="FilterContainerWrapper"
                  id="FilterContainerWrapperId"
                >
                    <div ref={ beforeRef } />
                    <Suspense fallback={ this.renderFilterPlaceholder() }>
                        <CategoryFilterOverlay
                          availableFilters={ filters }
                          customFiltersValues={ selectedFilters }
                          isMatchingInfoFilter={ isMatchingInfoFilter }
                          isCategoryAnchor={ !!is_anchor }
                          isSearchPage={ isSearchPage }
                          renderPlaceholder={ this.renderPlaceholder }
                        />
                    </Suspense>
                    <div ref={ afterRef } />
                </div>
            </div>
        );
    }

    renderFilterOverlayWrapper() {
        const { onStickyAfterEntryChange, onStickyBeforeEntryChange } = this.props;

        return (
            <InView onChange={ onStickyAfterEntryChange }>
                { ({ ref: afterRef }) => (
                    <InView onChange={ onStickyBeforeEntryChange }>
                        { ({ ref: beforeRef }) => this.renderFilterOverlay(afterRef, beforeRef) }
                    </InView>
                ) }
            </InView>
        );
    }

    /**
     * Overridden to display count in a manner that matches design
     */
    renderFiltersCount() {
        const { appliedFiltersCount } = this.props;

        if (!appliedFiltersCount) {
            return null;
        }

        return (
            <span block="CategoryPage" elem="Subheading">
                { appliedFiltersCount }
            </span>
        );
    }

    /**
     * New method to render categories slider
     */
    renderCategoriesSlider() {
        const {
            isThirdLevelCategory,
            sliderChildren = [],
            isCurrentCategoryLoaded,
            isMobile,
            category: {
                children = []
            } = {}
        } = this.props;
        // const {
        //     location: {
        //         state: {
        //             categoryChildrenCount
        //         } = {}
        //     } = {}
        // } = history;

        const { activeLayoutType } = this.state;

        // Removed temporary as it broke things on link click from other pages
        // if (!isThirdLevelCategory || (categoryChildrenCount !== undefined && !categoryChildrenCount)) {
        //     return null;
        // }

        if (!isThirdLevelCategory && !isMobile) {
            return null;
        }

        if (!isCurrentCategoryLoaded) {
            return this.renderSubcategorySliderFallback();
        }

        return (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ this.renderSubcategorySliderFallback() }>
                    <CategoriesSlider
                      category={ sliderChildren }
                      activeLayoutType={ activeLayoutType }
                      isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                      parentSliderCategory={ children.filter(({ product_count }) => product_count) }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderSubcategorySliderFallback() {
        const {
            location: {
                state: {
                    category,
                    categoryChildrenCount
                } = {}
            } = {}
        } = history;

        const {
            category: {
                id: categoryId = category
            } = {},
            isMobile
        } = this.props;

        const {
            actionName: {
                childrenCount = 0,
                type,
                id
            } = {}
        } = window;

        if (type === 'CATEGORY' && id === categoryId) {
            const mobileItems = childrenCount > CATEGORY_SLIDER_MAX_ITEMS_MOBILE
                ? CATEGORY_SLIDER_MAX_ITEMS_MOBILE
                : childrenCount;
            const items = isMobile ? mobileItems : childrenCount;

            return (
                <div block="CategoryPageFallback" elem="SubcategorySlider">
                    { Array.from(
                        { length: items },
                        this.renderSubcategorySliderItemFallback
                    ) }
                </div>
            );
        }

        const mobileItems = categoryChildrenCount > CATEGORY_SLIDER_MAX_ITEMS_MOBILE
            ? CATEGORY_SLIDER_MAX_ITEMS_MOBILE
            : categoryChildrenCount;
        const items = isMobile ? mobileItems : categoryChildrenCount;

        return (
            <div block="CategoryPageFallback" elem="SubcategorySlider">
                { Array.from(
                    { length: items === undefined ? CATEGORY_SLIDER_MAX_ITEMS_MOBILE : items },
                    this.renderSubcategorySliderItemFallback
                ) }
            </div>
        );
    }

    renderSubcategorySliderItemFallback() {
        const { activeLayoutType } = this.state;

        return (
            <div
              block="CategoryPageFallback"
              elem="SubcategorySliderItem"
              mods={ { isList: activeLayoutType === 'list' } }
            />
        );
    }

    /**
     * Renamed 'Shopping Options' to 'Your Selection'
     */
    renderFilterPlaceholder() {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    <h3 block="CategoryPage" elem="PlaceholderHeading">
                        { __('YOUR SELECTION:') }
                    </h3>
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                    </div>
                    <Loader isLoading />
                </div>
            </div>
        );
    }

    /**
     * Render block if it relates to current category
     */
    renderCategorySpecificCMS({ identifier, blockType }) {
        const {
            category: {
                url_key = ''
            } = {}
        } = this.props;

        // Eg: bedroom-category-latest-trends-top
        return (
            <Suspense fallback={ null }>
                <CmsBlock
                  identifier={ `${url_key}-category-${identifier}` }
                  blockType={ blockType }
                />
            </Suspense>
        );
    }

    /**
     * Renders CMS block on PLPs (Not on first level categories)
     */
    renderComfortForYouBlock() {
        return (
            <Suspense fallback={ null }>
                <CmsBlock identifier={ CategoryPageBlocks.COMFORT_FOR_YOU } />
            </Suspense>
        );
    }

    /**
     * Renders SEO text
     */
    renderSeoText() {
        const {
            category: {
                seo_text: seoText
            },
            canShowSeoBlock,
            isMatchingListFilter
        } = this.props;

        if (!seoText || !canShowSeoBlock) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ {
                  block: 'CategoryPage',
                  elem: 'SeoWrapper',
                  mods: { isHidden: !isMatchingListFilter }
              } }
            >
                <Html content={ seoText } />
            </ContentWrapper>
        );
    }

    /**
     * Overridden to pass isSearchPage prop to category details component
     */
    renderCategoryDetails() {
        const {
            category,
            isCurrentCategoryLoaded,
            isSearchPage,
            baseUrl,
            isMobile
        } = this.props;

        return (
            <Suspense fallback={ this.renderCategoryDetailsFallback() }>
                <CategoryDetails
                  category={ category }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isSearchPage={ isSearchPage }
                  baseUrl={ baseUrl }
                  isMobile={ isMobile }
                />
            </Suspense>
        );
    }

    renderCategoryDetailsFallback() {
        const {
            actionName: {
                name = '',
                description = '',
                imageUrl = null
            }
        } = window;

        return (
            <article block="CategoryDetailsFallback">
                <div block="CategoryDetailsFallback" elem="Description">
                    <p block="CategoryDetailsFallback" elem="Heading" mods={ { isReady: true } }>
                        { name }
                    </p>
                    <Html content={ description } />
                </div>
                <Image
                  mix={ { block: 'CategoryDetailsFallback', elem: 'Picture' } }
                  // eslint-disable-next-line no-magic-numbers
                  src={ getCloudflareOptimizationImage(imageUrl, 700) || '' }
                  ratio="custom"
                  objectFit="cover"
                  key={ imageUrl }
                  isPlaceholder={ !imageUrl }
                  onImageLoad={ setLoadedFlag }
                />
            </article>
        );
    }

    /**
     * Renders content after product list from category configuration
     */
    renderContentAfterProductList() {
        const {
            category: {
                content_after_product_list: contentAfterProductList
            },
            canShowSeoBlock
        } = this.props;

        if (!contentAfterProductList || !canShowSeoBlock) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ {
                  block: 'CategoryPage',
                  elem: 'ContentAfterProductList'
              } }
            >
                <Html content={ contentAfterProductList } />
            </ContentWrapper>
        );
    }

    renderMobileCategoryMenu() {
        const {
            closeMenu,
            isMobile,
            isMenuOpen,
            isCategoryButtonClick,
            activeCategory,
            clearButtonClick
        } = this.props;

        if (!(isMobile && isMenuOpen)) {
            return null;
        }

        if (isCategoryButtonClick) {
            return (
                <div block="CategoryPage" elem="MenuSection">
                    <Suspense fallback={ null }>
                        <Menu
                          clearInitialActiveSubcategory={ clearButtonClick }
                          initialActiveSubcategory={ activeCategory }
                          isCategoryButtonClick
                          closeMenuForCLP={ closeMenu }
                          isCLP
                        />
                    </Suspense>
                </div>
            );
        }

        return (
            <div block="CategoryPage" elem="CategoryPage">
                <Suspense fallback={ null }>
                    <Menu
                      closeMenuForCLP={ closeMenu }
                      isCLP
                    />
                </Suspense>
            </div>
        );
    }

    renderItemsCount(isVisibleOnMobile = false) {
        const { isMatchingListFilter, isMobile } = this.props;

        if ((isVisibleOnMobile && !isMobile) || (!isVisibleOnMobile && isMobile)) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <CategoryItemsCount
                  isMatchingListFilter={ isMatchingListFilter }
                />
            </Suspense>
        );
    }

    renderCategorySort() {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile
        } = this.props;

        const { options = {} } = sortFields;
        const updatedSortFields = Object.values(options).map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        return (
            <Suspense fallback={ null }>
                <CategorySort
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  onSortChange={ onSortChange }
                  sortFields={ updatedSortFields }
                  sortKey={ sortKey }
                  sortDirection={ sortDirection }
                />
            </Suspense>
        );
    }

    renderFilterButton() {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if ((!isContentFiltered && totalPages === 0) || (!is_anchor && !isSearchPage) || !isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ onFilterButtonClick }
            >
                <Suspense fallback={ null }>
                    <FilterIcon />
                </Suspense>
                <span>{ __('Filters') }</span>
                { this.renderFiltersCount() }
            </button>
        );
    }

    /**
     * Overridden to:
     * - Remove renderItemsCount usage
     * - Pass gridLayoutTotalColumn to CategoryProductList
     */
    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            gridLayoutTotalColumn,
            showSeoBlock
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              id="ProductListWrapperId"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <Suspense fallback={ this.renderProductListFallback() }>
                    <CategoryProductList
                      filter={ filter }
                      search={ search }
                      sort={ selectedSort }
                      selectedFilters={ selectedFilters }
                      isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                      isMatchingListFilter={ isMatchingListFilter }
                      isMatchingInfoFilter={ isMatchingInfoFilter }
                      layout={ activeLayoutType || GRID_LAYOUT }
                      gridLayoutTotalColumn={ gridLayoutTotalColumn }
                      showSeoBlock={ showSeoBlock }
                    />
                </Suspense>
            </div>
        );
    }

    renderProductListFallback() {
        const {
            actionName: {
                imageUrl = ''
            } = {}
        } = window;

        const { pages = {} } = this.props;
        const [items] = Object.values(pages);

        if (imageUrl || !items?.length) {
            return (
                <div block="CategoryPageFallback" elem="ProductListWrapper">
                    <ul block="CategoryPageFallback" elem="ProductList">
                        { this.renderProductListPlaceholderFallback() }
                    </ul>
                </div>
            );
        }

        return (
            <div block="CategoryPageFallback" elem="ProductListWrapper">
                <ul block="CategoryPageFallback" elem="ProductList">
                    { items.map(this.renderProductListItemFallback) }
                </ul>
            </div>
        );
    }

    renderProductListItemFallback(item, index) {
        const {
            name = '',
            small_image: {
                url = ''
            } = {},
            media_gallery_entries = []
        } = item;

        if (index >= PRODUCT_LIST_PLACEHOLDER_AMOUNT) {
            return null;
        }

        if (index >= PRODUCT_LIST_VISIBLE_PRODUCTS_AMOUNT) {
            return this.renderProductListItemPlaceholderFallback();
        }

        if (media_gallery_entries.length) {
            const {
                base: {
                    url: galleryUrl = ''
                } = {}
            } = media_gallery_entries[0];

            return this.renderProductListItemFallbackCard(name, galleryUrl);
        }

        return this.renderProductListItemFallbackCard(name, url);
    }

    renderProductListItemFallbackCard(name, url) {
        return (
            <li block="CategoryPageFallback" elem="ProductCard">
                <Image
                  src={ url }
                  alt={ name }
                  ratio="custom"
                  onImageLoad={ setLoadedFlag }
                  onError={ setLoadedFlag }
                />
            </li>
        );
    }

    renderProductListPlaceholderFallback() {
        return Array.from(
            { length: PRODUCT_LIST_PLACEHOLDER_AMOUNT },
            this.renderProductListItemPlaceholderFallback
        );
    }

    renderProductListItemPlaceholderFallback() {
        return (
            <li block="CategoryPageFallback" elem="ProductCard">
                <div block="CategoryPageFallback" elem="ProductCardImage" />
            </li>
        );
    }

    renderMobileGridLayoutButton(type) {
        const {
            onGridButtonClick,
            gridLayoutTotalColumn
        } = this.props;
        const { activeLayoutType } = this.state;
        const isGridActive = activeLayoutType === GRID_LAYOUT
            && gridLayoutTotalColumn === GRID_LAYOUT_MULTIPLE_COLUMNS;

        return (
            <button
              key={ type }
              onClick={ onGridButtonClick }
              mix={ {
                  block: GRID_LAYOUT,
                  mods: { isActive: isGridActive }
              } }
              aria-label="grid"
            >
                <Suspense fallback={ null }>
                    <GridIcon isActive={ isGridActive } />
                </Suspense>
            </button>
        );
    }

    renderMobileListLayoutButton(type) {
        const {
            onListButtonClick,
            gridLayoutTotalColumn
        } = this.props;
        const { activeLayoutType } = this.state;
        const isListActive = activeLayoutType === GRID_LAYOUT
            && gridLayoutTotalColumn === GRID_LAYOUT_ONE_COLUMN;

        return (
            <button
              key={ type }
              onClick={ onListButtonClick }
              mix={ {
                  block: LIST_LAYOUT,
                  mods: { isActive: isListActive }
              } }
              aria-label="list"
            >
                <Suspense fallback={ null }>
                    <ListIcon isActive={ isListActive } />
                </Suspense>
            </button>
        );
    }

    /**
     * New method to render mobile layout button
     */
    renderMobileLayoutButton(type) {
        const renderer = this.renderMobileLayoutButtonMap[type];

        if (!renderer) {
            return null;
        }

        return renderer(type);
    }

    renderGridLayoutButton(type) {
        const { onGridButtonClick } = this.props;
        const { activeLayoutType } = this.state;

        return (
            <button
              key={ type }
              onClick={ onGridButtonClick }
              mix={ { block: GRID_LAYOUT, mods: { isActive: activeLayoutType === GRID_LAYOUT } } }
              aria-label="grid"
            >
                <Suspense fallback={ null }>
                    <GridIcon isActive={ activeLayoutType === GRID_LAYOUT } />
                </Suspense>
            </button>
        );
    }

    renderListLayoutButton(type) {
        const { onListButtonClick } = this.props;
        const { activeLayoutType } = this.state;

        return (
            <button
              key={ type }
              onClick={ onListButtonClick }
              mix={ { block: LIST_LAYOUT, mods: { isActive: activeLayoutType === LIST_LAYOUT } } }
              aria-label="list"
            >
                <Suspense fallback={ null }>
                    <ListIcon isActive={ activeLayoutType === LIST_LAYOUT } />
                </Suspense>
            </button>
        );
    }

    /**
     * Overridden to add renderMobileLayoutButton and renderLayoutButtonMap usage
     */
    renderLayoutButton(type) {
        const { isMobile } = this.props;

        if (isMobile) {
            return this.renderMobileLayoutButton(type);
        }

        const renderer = this.renderLayoutButtonMap[type];

        if (!renderer) {
            return null;
        }

        return renderer(type);
    }

    /**
     * New method to render magefan posts widget
     */
    renderMagefanBlogPosts() {
        return (
            <ContentWrapper
              mix={ {
                  block: 'CategoryPage',
                  elem: 'ContentAfterProductList'
              } }
            >
                <Suspense fallback={ null }>
                    <CmsBlock identifier={ CategoryPageBlocks.MAGEFAN_BLOG_POSTS } />
                </Suspense>
            </ContentWrapper>
        );
    }

    /**
     * Overridden to render category details above the wrapper and add renderCategoriesSlider usage
     */
    render() {
        const hideProducts = !this.displayProducts();
        const {
            totalItems,
            isMenuOpen,
            category: {
                url_key: urlKey = '',
                is_banner_active: isBannerActive
            } = {}
        } = this.props;

        return (
            <>
                { this.renderMobileCategoryMenu() }
                <main
                  block="CategoryPage"
                  mods={ {
                      noResults: totalItems === 0,
                      isHidden: isMenuOpen,
                      isOffersPage: urlKey === CATEGORY_OFFERS_URL_KEY
                  } }
                >
                    <div
                      block="CategoryPage"
                      elem="CategoryDetailsWrapper"
                      mods={ { isBannerActive } }
                    >
                        <ContentWrapper
                          label={ __('Category details') }
                        >
                            { this.renderCategoryDetails() }
                        </ContentWrapper>
                    </div>
                    { this.renderCategoriesSlider() }
                    <ContentWrapper
                      wrapperMix={ {
                          block: 'CategoryPage',
                          elem: 'Wrapper',
                          mods: { hideProducts }
                      } }
                      label={ __('Category page') }
                    >
                        { this.renderContent() }
                    </ContentWrapper>
                    <AfterPriority fallback={ null }>
                        { this.renderContentAfterProductList() }
                        { this.renderMagefanBlogPosts() }
                        { this.renderSeoText() }
                    </AfterPriority>
                </main>
            </>
        );
    }
}

export const MemoizedCategoryPageComponent = memo(CategoryPageComponent, (prevProps, nextProps) => {
    const {
        selectedFilters,
        filter,
        sortFields,
        selectedSort,
        ...restProps
    } = prevProps;

    const {
        selectedFilters: nextSelectedFilters,
        filter: nextFilter,
        sortFields: nextSortFields,
        selectedSort: nextSelectedSort,
        ...restNextProps
    } = nextProps;

    if (
        JSON.stringify(selectedFilters) !== JSON.stringify(nextSelectedFilters)
        || JSON.stringify(filter) !== JSON.stringify(nextFilter)
        || JSON.stringify(sortFields) !== JSON.stringify(nextSortFields)
        || JSON.stringify(selectedSort) !== JSON.stringify(nextSelectedSort)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default withReducers({
    AmastyAutomaticRelatedProductsReducer
})(MemoizedCategoryPageComponent);
