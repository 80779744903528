import PropTypes from 'prop-types';

export const SelectedSortType = PropTypes.shape({
    sortKey: PropTypes.string,
    sortDirection: PropTypes.string
});

export const CategoryStickyFiltersType = PropTypes.shape({
    selectedSort: SelectedSortType,
    isCurrentCategoryLoaded: PropTypes.bool,
    isMatchingInfoFilter: PropTypes.bool,
    onSortChange: PropTypes.func,
    isContentFiltered: PropTypes.bool,
    totalPages: PropTypes.number,
    isAnchor: PropTypes.bool,
    isSearchPage: PropTypes.bool,
    onFilterButtonClick: PropTypes.func,
    appliedFiltersCount: PropTypes.number
});
