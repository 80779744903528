/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CmsBlock from 'Component/CmsBlock';
import Image from 'Component/Image';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { POST_PAGE_TYPE } from '../route/BlogPage/BlogPage.config';
import { formatDate } from '../util/BlogPage';

const renderCategory = (cat) => <div block="CategoryDetails" elem="Category">{ cat.title }</div>;

const renderCategories = (categories) => {
    if (!categories || !categories?.length) {
        return null;
    }

    return (
        <div block="CategoryDetails" elem="Categories">
            { categories.map((cat) => renderCategory(cat)) }
        </div>
    );
};

const renderPublishDate = (publishDate) => formatDate(publishDate);

const addPostCategoryAndDate = (args, callback, instance) => {
    const {
        blogPageType
    } = instance.props;

    const { postDetails } = getStore().getState().MagefanBlogReducer;

    const {
        categories,
        publishDate
    } = postDetails || {};

    if (!blogPageType || blogPageType !== POST_PAGE_TYPE) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <div block="CategoryDetails" elem="PostDetails">
               { renderCategories(categories) }
                <div block="CategoryDetails" elem="PublishDate">
                    { renderPublishDate(publishDate) }
                </div>
            </div>
        </>
    );
};

const addPostPageMod = (args, callback, instance) => {
    const {
        category: { image, id },
        isCurrentCategoryLoaded,
        blogPageType
    } = instance.props;

    if (!blogPageType) {
        return callback(...args);
    }

    if (!id || !isCurrentCategoryLoaded) {
        return this.renderCategoryImagePlaceholder();
    }

    if (!image) {
        return null;
    }

    const isPostPage = blogPageType === POST_PAGE_TYPE;

    if (!isPostPage) {
        return <CmsBlock identifier="magefan-blog-index-image" />;
    }

    return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Picture', mods: { isPostPage } } }
              src={ image || '' }
              ratio="custom"
              objectFit="cover"
              onImageLoad={ setLoadedFlag }
            />
    );
};

export default {
    'Component/CategoryDetails/Component': {
        'member-function': {
            renderCategoryDescription: addPostCategoryAndDate,
            renderCategoryImage: addPostPageMod
        }
    }
};
