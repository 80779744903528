/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DELIVERY_MODES_BY_CODE } from 'Component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.config';
import SlotsQuery from 'Query/Slots.query';
import AreasReducer from 'Store/Areas/Areas.reducer';
import { updateSelectedSlot } from 'Store/CheckoutDelivery/CheckoutDelivery.action';
import CheckoutDeliveryReducer from 'Store/CheckoutDelivery/CheckoutDelivery.reducer';
import MenuReducer from 'Store/Menu/Menu.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { Addresstype } from 'Type/Account.type';
import { AreasType } from 'Type/Areas.type';
import {
    getDay,
    getFullDateInDigits,
    getMonth
} from 'Util/Date';
import { withReducers } from 'Util/DynamicReducer';
import {
    areSlotsEnabledForMethod,
    areSlotsEnabledForMethodWithoutApi
} from 'Util/OfflineShipping';
import { fetchQuery } from 'Util/Request';
import { getCountryCode } from 'Util/Store';

import { getLocalization } from '../../util/Store';
import CheckoutSlotsComponent from './CheckoutSlots.component';
import {
    MAX_SLOTS_FOR_METHOD,
    TIMES
} from './CheckoutSlots.config';

export const AreasDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/Areas/Areas.dispatcher'
);

/** @namespace Scandipwa/Component/CheckoutSlots/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    areas: state.AreasReducer.areas,
    storeCode: state.ConfigReducer.code,
    panOrderBeforeXDeliveryDelay: state.ConfigReducer?.orderBeforeXDeliveryConfig?.pan_order_before_x_delivery_delivery_delay_in_day,
    panOrderBeforeXDeliveryOffDay: state.ConfigReducer?.orderBeforeXDeliveryConfig?.pan_order_before_x_delivery_off_day,
    timezone: state.ConfigReducer?.timezone,
    isMobile: state.ConfigReducer?.device.isMobile
});

/** @namespace Scandipwa/Component/CheckoutSlots/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getAreas: () => AreasDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    ),
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    onSlotSelect: (selectedSlot) => dispatch(updateSelectedSlot(selectedSlot))
});

/** @namespace Scandipwa/Component/CheckoutSlots/Container */
export class CheckoutSlotsContainer extends PureComponent {
    static propTypes = {
        methodCode: PropTypes.string.isRequired,
        areas: AreasType.isRequired,
        estimateAddress: Addresstype.isRequired,
        storeCode: PropTypes.string.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        onSlotSelect: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        getAreas: PropTypes.func.isRequired,
        isMobile: PropTypes.bool,
        panOrderBeforeXDeliveryDelay: PropTypes.number,
        panOrderBeforeXDeliveryOffDay: PropTypes.number
    };

    static defaultProps = {
        ...this.defaultProps,
        isMobile: false,
        panOrderBeforeXDeliveryDelay: 0,
        panOrderBeforeXDeliveryOffDay: 0
    };

    containerFunctions = {
        onTimeClickHandler: this.onTimeClickHandler.bind(this),
        onDateClickHandler: this.onDateClickHandler.bind(this)
    };

    componentDidMount() {
        const {
            areas,
            getAreas,
            methodCode
        } = this.props;

        if (!areas.length) {
            getAreas();
        }

        if (areSlotsEnabledForMethod(methodCode) || areSlotsEnabledForMethodWithoutApi(methodCode)) {
            this.requestSlots();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDateIndex: prevSelectedDateIndex } = prevState;
        const { selectedDateIndex } = this.state;
        const {
            estimateAddress,
            methodCode
        } = this.props;
        const {
            estimateAddress: prevEstimateAddress
        } = prevProps;

        const shouldCheck = estimateAddress?.city && estimateAddress?.region;

        if (selectedDateIndex !== prevSelectedDateIndex) {
            this.getEstimatedDeliveryTime(selectedDateIndex);
        }

        if (
            shouldCheck
            && (
                estimateAddress?.city !== prevEstimateAddress?.city
                || estimateAddress?.region !== prevEstimateAddress?.region
            )
        ) {
            if (areSlotsEnabledForMethod(methodCode) || areSlotsEnabledForMethodWithoutApi(methodCode)) {
                this.requestSlots();
            }
        }
    }

    __construct(props) {
        super.__construct(props, 'CheckoutSlotsContainer');

        this.state = {
            slots: [],
            clusterCode: '',
            selectedTimeIndex: 0,
            selectedDateIndex: 0,
            isLoading: false,
            estimatedDeliverDate: ''
        };
    }

    async requestSlots() {
        const {
            methodCode,
            showErrorNotification
        } = this.props;

        const clusterCode = this.getClusterCode();

        if (!clusterCode || !DELIVERY_MODES_BY_CODE[methodCode]) {
            return;
        }

        this.setState({ isLoading: true });

        if (areSlotsEnabledForMethodWithoutApi(methodCode) && !areSlotsEnabledForMethod(methodCode)) {
            this.getFakeSlots();

            return;
        }

        try {
            const {
                PanGetAvailableSlotsForMethod,
                PanGetAvailableSlotsForMethod: {
                    slots
                }
            } = await fetchQuery(SlotsQuery.getSlotsForMethodQuery({
                clusterCode,
                shippingMethod: methodCode
            }));

            const { onSlotSelect } = this.props;

            if (!PanGetAvailableSlotsForMethod.length) {
                onSlotSelect(null);
            }

            const MAX_SLOTS = MAX_SLOTS_FOR_METHOD[methodCode];

            const filteredSlots = this.removeDuplicateSlotsAndSort(slots)
                .slice(0, MAX_SLOTS);

            const {
                selectedDateIndex,
                selectedTimeIndex
            } = this.getInitialSelectedDate(filteredSlots);

            this.setState({
                slots: filteredSlots,
                clusterCode,
                isLoading: false
            }, () => {
                this.onDateClickHandler(selectedDateIndex, selectedTimeIndex);
            });
        } catch (error) {
            showErrorNotification(__('Error loading slots, please try again later'));
            this.setState({ isLoading: false });
        }
    }

    getFakeSlots() {
        const {
            methodCode,
            panOrderBeforeXDeliveryDelay,
            panOrderBeforeXDeliveryOffDay
        } = this.props;

        const MAX_SLOTS = MAX_SLOTS_FOR_METHOD[methodCode];
        const deliveryDate = new Date();
        deliveryDate.setDate(deliveryDate.getDate() + panOrderBeforeXDeliveryDelay);

        const result = Array.from({ length: MAX_SLOTS }, () => {
            deliveryDate.setDate(deliveryDate.getDate() + 1);

            if (deliveryDate.getDay() === panOrderBeforeXDeliveryOffDay) {
                return {
                    delivery_date: deliveryDate.toISOString(),
                    slot_details: []
                };
            }

            const slotDetails = Array.from({ length: 3 }, (_, i) => {
                if (i === 0) {
                    return {
                        from_time: '13:00',
                        slot_code: 'A01',
                        slot_name: 'Afternoon',
                        to_time: '17:00'
                    };
                }

                if (i === 1) {
                    return {
                        from_time: '17:00',
                        slot_code: 'E01',
                        slot_name: 'Evening',
                        to_time: '20:00'
                    };
                }

                return {
                    from_time: '09:00',
                    slot_code: 'M01',
                    slot_name: 'Morning',
                    to_time: '13:00'
                };
            });

            return {
                delivery_date: deliveryDate.toISOString(),
                slot_details: slotDetails
            };
        });

        const clusterCode = this.getClusterCode();

        const filteredSlots = this.removeDuplicateSlotsAndSort(result)
            .slice(0, MAX_SLOTS);

        const {
            selectedDateIndex,
            selectedTimeIndex
        } = this.getInitialSelectedDate(filteredSlots);

        this.setState({
            slots: filteredSlots,
            clusterCode,
            isLoading: false
        }, () => {
            this.onDateClickHandler(selectedDateIndex, selectedTimeIndex);
        });
    }

    removeDuplicateSlotsAndSort(data) {
        const { panOrderBeforeXDeliveryDelay, panOrderBeforeXDeliveryOffDay } = this.props;

        data.sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));

        const sorted = data.map((delivery) => {
            const seenSlots = [];

            const filteredDetails = delivery.slot_details
                .filter((slot) => {
                    const duplicate = seenSlots.some((seenSlot) => seenSlot.from_time === slot.from_time
                    && seenSlot.to_time === slot.to_time
                    && seenSlot.slot_code === slot.slot_code
                    && seenSlot.slot_name === slot.slot_name);

                    if (!duplicate) {
                        seenSlots.push(slot);
                    }

                    return !duplicate;
                })
                .sort((({ from_time: t1 }, { from_time: t2 }) => t1.localeCompare(t2)));

            return { ...delivery, slot_details: filteredDetails };
        });

        const deliveryDate = new Date();
        deliveryDate.setDate(deliveryDate.getDate() + panOrderBeforeXDeliveryDelay);

        return sorted?.filter((slot) => {
            const date = new Date(slot.delivery_date);

            return date.getDay() !== panOrderBeforeXDeliveryOffDay && date > deliveryDate;
        });
    }

    getInitialSelectedDate(slots) {
        const selectedDateIndex = slots.findIndex(({ slot_details }) => slot_details.length);

        if (selectedDateIndex === -1) {
            return {};
        }

        const [timeSlot] = slots[selectedDateIndex].slot_details;
        const { from_time, to_time } = timeSlot;

        const selectedTimeIndex = TIMES.indexOf(`${from_time}-${to_time}`);

        return {
            selectedDateIndex,
            selectedTimeIndex
        };
    }

    getClusterCode() {
        const {
            storeCode,
            estimateAddress: {
                city,
                region
            } = {},
            areas,
            showErrorNotification
        } = this.props;

        if (!city) {
            showErrorNotification(__('The city is not available, please select another city'));

            return null;
        }

        if (!region) {
            showErrorNotification(__('The region is not available, please select another region'));

            return null;
        }

        const country = getCountryCode(storeCode);

        const clusterCode = areas.find(({ code: countryCode }) => countryCode === country)
            ?.subCountries.find(({ label: cityLabel }) => cityLabel === city)
            ?.areaMasters.find(({ label: regionLabel }) => regionLabel === region)
            ?.code;

        if (!clusterCode) {
            return '';
        }

        // not sure why they changed cluster code they are using on BE, for now going with this parsing.
        const dashIndex = clusterCode.indexOf('-');
        const actualCode = dashIndex !== -1 ? clusterCode?.substr(0, dashIndex) : clusterCode;

        return actualCode;
    }

    getDates() {
        const { slots = [] } = this.state;
        const { storeCode } = this.props;

        const localization = getLocalization(storeCode);

        return slots.map(({ delivery_date, slot_details }) => {
            const date = new Date(delivery_date);

            return {
                day: date.getDate(),
                month: getMonth(date, localization),
                dayOfTheWeek: getDay(date, localization),
                times: slot_details
                    .map(({ from_time, to_time }) => `${from_time}-${to_time}`)
            };
        });
    }

    getEstimatedDeliveryTime(selectedDateIndex = 0) {
        const { slots = [] } = this.state;

        if (!slots.length) {
            return '';
        }

        const { delivery_date } = slots[selectedDateIndex];
        const date = new Date(delivery_date);

        this.setState({ estimatedDeliverDate: getFullDateInDigits(date, '/', 2, 2) });

        return getFullDateInDigits(date, '/', 2, 2);
    }

    onTimeClickHandler(index) {
        const { selectedDateIndex } = this.state;

        this.onDateClickHandler(selectedDateIndex, index);
    }

    onDateClickHandler(index, timeIndex = null) {
        const {
            onSlotSelect
        } = this.props;

        const { slots, clusterCode } = this.state;

        if (!slots[index]) {
            return;
        }

        const { delivery_date, slot_details } = slots[index];
        const { slot_code } = slot_details[timeIndex || 0] || {};

        this.setState({ selectedDateIndex: index, selectedTimeIndex: timeIndex || 0 });

        onSlotSelect({
            delivery_date,
            slot_code,
            clusterCode
        });
    }

    containerProps() {
        const {
            selectedDateIndex,
            isLoading,
            selectedTimeIndex,
            estimatedDeliverDate
        } = this.state;

        const { isMobile, methodCode } = this.props;

        return {
            dates: this.getDates(),
            estimatedDeliveryTime: estimatedDeliverDate || this.getEstimatedDeliveryTime(selectedDateIndex),
            selectedDateIndex,
            selectedTimeIndex,
            isLoading,
            isMobile,
            isHideTimeSelection: !areSlotsEnabledForMethod(methodCode)
        };
    }

    render() {
        return (
            <CheckoutSlotsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withReducers({
    MenuReducer,
    AreasReducer,
    CheckoutDeliveryReducer
})(connect(mapStateToProps, mapDispatchToProps)(CheckoutSlotsContainer));
