/**
 * GA4MeasurementProtocol frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';
import { fetchMutation, fetchQuery } from 'Util/Request';

import GaCookieQuery from '../query/GaCookie.query';

export const GUEST_QUOTE_ID = 'guest_quote_id';

export const getGaCid = () => {
    const [, result = ''] = document.cookie.match(/_ga=([^;]+);/) || [];

    return result;
};

export const getSessionId = (session_cookie) => {
    const regexPattern = new RegExp(
        `${session_cookie}=GS\\d\\.\\d\\.(.+?)(?:;|$)`
    );
    const [, result = ''] = document.cookie.match(regexPattern) || [];

    return result;
};

const getAdditionalData = () => {
    const { referrer = '', landingPageUrl = '' } = window.sessionStorage;

    return {
        referrer,
        landingPageUrl
    };
};

export const componentDidMount = async (args, callback, instance) => {
    try {
        callback(...args);

        const { totals: { id: cartId } = {} } = instance.props;
        const gaCid = getGaCid();
        const guestCartId = isSignedIn() ? null : cartId;
        const {
            getGaSessionCookie: session_cookies = {}
        } = await fetchQuery(GaCookieQuery.getGaSessionCookie()) || {};

        Object.values(session_cookies).forEach((session_cookie) => {
            const session_id = getSessionId(session_cookie);
            const additionalData = getAdditionalData();

            if (!session_id) {
                return;
            }

            fetchMutation(
                GaCookieQuery.setGaCookie(gaCid, session_id, guestCartId, session_cookie, additionalData)
            );
        });
    } catch (error) {
        callback(...args);
    }
};

// Both Checkout and Cart plugins are needed because sometimes user
// can place an order without proceeding to checkout (ex. paypal)
export default {
    'Route/CartPage/Component': {
        'member-function': {
            componentDidMount
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            componentDidMount
        }
    }
};
