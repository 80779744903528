/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

function addProductToCart(args, cb) {
    const [dispatch, { products = [], ...rest }] = args;
    const breadcrumbs = getStore().getState().BreadcrumbsReducer?.breadcrumbs || [];
    const categoryString = breadcrumbs.reduceRight((acc, crumb) => {
        const {
            url: {
                state: {
                    category,
                    categoryTitle
                } = {}
            } = {}
        } = crumb;

        if (category) {
            return `${acc}//${categoryTitle}`;
        }

        return acc;
    }, '');

    const populatedProducts = products.map((product) => ({
        ...product,
        ga_categories: categoryString
    }));

    cb(dispatch, { products: populatedProducts, ...rest });
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart
        }
    }
};
